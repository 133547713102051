<template>
  <div class="passport-certification-page default-layout-desktop-padding">
    <div class="passport-certification-page__main-container flex-column h-100">
      <WelcomeBanner
        header="Your Professional Certifications"
        buttonLabel="add new certification"
        @handle-event="onToggleAccreditationModal(true)"/>
      <div class="flex-column gap-2">
        <div class="passport-certification-page__wrapper flex-column">
            <div class="flex-row gap-half">
                <Icon
                iconName="specialisms-dark.svg"
                customPath="icons/trade-passport-icons"
                iconWidth="20px" iconHeight="20px"/>
                <Typography variant="h6">Your Certifications</Typography>
            </div>
            <div class="passport-certification-page__content col-2 w-100 grid">
              <CertificationItemCard
                v-for="(certificate, index) in availableCertificates"
                :key="index"
                :certificate="certificate"
                @remove-certificate="onRemoveCertificate"/>
            </div>
        </div>
        <el-divider/>
        <div class="passport-certification-page__wrapper flex-column">
            <div class="flex-row gap-half">
                <Typography variant="h6">Your Next Accreditations</Typography>
            </div>
            <div class="passport-certification-page__content col-3 w-100 grid">
            <NextAccreditationItem/>
            <NextAccreditationItem/>
            <NextAccreditationItem/>
            </div>
        </div>
      </div>

      <!-- MODALS -->
      <AddAccreditationModal
        :show="showAccreditationModal"
        :userCertificates="userCertificates"
        @on-cancel="onCloseModal"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import NextAccreditationItem from '@/core/components/common/item/NextAccreditationItem.vue';
import WelcomeBanner from '@/core/components/layouts/WelcomeBanner.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import statusType from '@/core/constants/status/type';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import CertificationItemCard from '@/modules/trades-passport-children/components/card-item/CertificationItemCard.vue';
import AddAccreditationModal from '@/modules/trades-passport-children/components/modals/AddAccreditationModal.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'TradesPassportOverview',

  components: {
    WelcomeBanner,
    Typography,
    Icon,
    CertificationItemCard,
    NextAccreditationItem,
    AddAccreditationModal
  },

  data() {
    return {
      UserVerificationStatusEnum: {
        ...UserVerificationStatusEnum,
      },
      statusType,
      showAccreditationModal: false,
      userCertificates: [],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user', 'accountStatuses', 'isProfileVerifiable', 'isHosted']),

    availableCertificates() {
      const { userCertificates } = this;

      if (userCertificates.length) {
        return this.normalizeCertificates(userCertificates);
      }

      return [];
    },
  },

  async created() {
    await this.onInitialize();
  },

  methods: {
    ...mapActions(USERS_STORE, [
      // certificates
      'addNewCertificate',
      'removeUserCertificates',
      'getUserCertificates',
    ]),

    onToggleAccreditationModal(event) {
      this.setAccreditationModal(event);
    },

    setAccreditationModal(event) {
      this.showAccreditationModal = event;
    },

    async onInitialize() {
      const { user } = this;
      const userId = user.id;
      this.userCertificates = [];

      if (!this.userCertificates.length) {
        await this.setUserCertificates(userId);
      }
    },

    async setUserCertificates(userId) {
      this.userCertificates = await this.getUserCertificates(userId).catch(() => {}) || [];
    },

    normalizeCertificates(userCertificates) {
      return userCertificates.map((certificate) => {
        return {
          ...certificate,
          status: this.getStatus(certificate.userVerificationStatus),
        };
      });
    },

    getStatus(verificationState) {
      const statusState = verificationState;

      switch (statusState) {
        case UserVerificationStatusEnum.NOT_ADDED:
          return {
            type: statusType.ALERT,
            statusName: 'VERIFICATION',
            spanText: 'REQUIRED'
          };
        case UserVerificationStatusEnum.PENDING_VERIFICATION:
          return {
            type: statusType.ALERT,
            statusName: 'PENDING',
            spanText: 'VERIFICATION'
          };
        case UserVerificationStatusEnum.VERIFIED:
          return {
            type: statusType.VERIFIED,
            statusName: 'VERIFIED',
          };
        case UserVerificationStatusEnum.REJECTED:
          return {
            type: statusType.ALERT,
            statusName: 'REJECTED',
          };
        default:
          return {
            type: statusType.ALERT,
            statusName: 'VERIFICATION',
            spanText: 'REQUIRED'
          };
      }
    },

    onCloseModal(hasSaveNewData = false) {
      if (hasSaveNewData) {
        this.onInitialize();
      }

      this.onToggleAccreditationModal(false);
    },

    onRemoveCertificate(userCertificateId) {
      this.removeUserCertificates({
        userId: this.user.id,
        userCertificateId
      })
        .then(async () => {
          this.$notify.success({
            message: 'Certificate successfully removed.'
          });
          this.onInitialize();
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error removing certificate details.'
          });
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.passport-certification-page {
  &__content {
  }

  &__content.col-2 {
    grid-template-columns: 1fr 1fr;
  }

  &__content.col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
