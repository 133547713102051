<template>
    <InfoCardSlot
      class="certification-item"
      :hasCustomHeader="true"
      :hasCustomFooter="certificate.userVerificationStatus !== UserVerificationStatusEnum.NOT_ADDED"
      actionLabel="Verify Certification">
      <template #custom-header>
        <Status :status="certificate.status"/>
      </template>
      <template #custom-content>
        <div class="certification-item__content flex-column jc-fs gap-1 h-auto w-100">
          <div class="certification-item__wrapper flex-column w-100">
            <div class="flex-row ai-c jc-sb">
                <!-- NOTE: Create a reusable component for this molecule -->
                <div class="flex-row ai-fs gap-half">
                    <!-- NOTE: Create a reusable component for this icon placeholder-->
                    <span class="certification-item__category-icon flex-row ai-c jc-c">
                      <inline-svg
                      class="certification-item__img"
                      width="20px" height="20px"
                      :src="require(`@/assets/category-icons/carpentry-joinery.svg`)"></inline-svg>
                    </span>
                    <div class="passport-profile__wrapper flex-column gap-0">
                      <Typography variant="h6" lineHeight="14px">
                        {{ certificate.certificate?.name }}
                      </Typography>
                      <Typography
                        variant="custom"
                        textSize="0.75rem"
                        textWeight="400"
                        textColor="rgba(12, 15, 74, .5)">
                          {{ certificate.metadata?.registrationNumber }}
                      </Typography>
                      <!-- <Typography
                        variant="custom"
                        textSize="0.75rem"
                        textWeight="400"
                        textColor="rgba(12, 15, 74, .5)">
                          Exp: 31 /12 /2024
                      </Typography> -->
                      <Typography
                        variant="custom"
                        textSize="0.75rem"
                        textWeight="400"
                        textColor="rgba(12, 15, 74, .5)">
                          {{ certificate.metadata?.url }}
                      </Typography>
                    </div>
                </div>
                <Icon
                  v-if="certificate.userVerificationStatus === UserVerificationStatusEnum.NOT_ADDED"
                  class="cursor-pointer"
                  iconName="trash-icon.svg"
                  iconHeight="15px"
                  iconWidth="15px"/>
            </div>
          </div>
        </div>
      </template>
      <template #custom-footer>
        <div class="flex-row ai-c jc-sb w-100">
          <div class="flex-row ai-c">
            <Icon
              class="cursor-pointer"
              iconName="pdf-icon.svg"
              iconHeight="15px"
              iconWidth="15px"/>
            <Typography
              variant="custom"
              textSize="0.75rem"
              textWeight="500">
                Accreditation.pdf
            </Typography>
          </div>
          <Icon
            @click="removeCertification(certificate.id)"
            class="cursor-pointer"
            iconName="trash-icon.svg"
            iconHeight="15px"
            iconWidth="15px"/>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import Status from '@/core/components/common/status/Status.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Icon,
    Status
  },

  emits: ['remove-certificate'],

  props: {
    certificate: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      UserVerificationStatusEnum: {
        ...UserVerificationStatusEnum,
      }
    };
  },

  methods: {
    removeCertification(id) {
      this.$emit('remove-certificate', id);
    }
  },
});
</script>
<style lang="scss" scoped>
.certification-item {
  max-height: unset !important;
  height: auto !important;

  &__content {
  }
  &__wrapper.grid {
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}
</style>
