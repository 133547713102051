<template>
   <div class="next-acc-item default-card flex-row ai-c jc-sb cursor-pointer">
      <div class="flex-row gap-half h-100">
        <ProjectItemThumbnail class="next-acc-item__thumbnail" :project="item"/>
        <div class="flex-column gap-0">
            <Typography variant="p" lineHeight="14px">Air Source Heat Pump Level 3</Typography>
            <Typography
                variant="custom"
                textSize="0.75rem"
                textWeight="400"
                textColor="rgba(12, 15, 74, .5)">
                Level 3 Qualification
            </Typography>
        </div>
      </div>
      <Icon iconName="arrow-right-circular-filled.svg" iconHeight="32px" iconWidth="32px"/>
   </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import ProjectItemThumbnail from '@/modules/property-details-children/components/ProjectItemThumbnail.vue';

export default defineComponent({
  name: 'NextAccreditationItem',

  components: {
    Typography,
    ProjectItemThumbnail,
    Icon
  }
});
</script>
<style lang="scss" scoped>
.next-acc-item {
    max-height: 80px;
    min-height: 80px;
    height: 100%;
    width: 100%;

   &__thumbnail {
    min-width: 52px !important;
    min-height: 52px !important;
    max-width: 52px !important;
    max-height: 52px !important;
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
