<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="add-accreditation flex-column jc-sb">
        <div class="add-accreditation__container flex-column">
          <div class="add-accreditation__header flex-row ai-c jc-sb">
              <div class="flex-column ai-fs">
                <h2 class="add-accreditation__title default-text">{{ isOnEditModal ? 'Edit accreditation' : 'Add accreditation' }}</h2>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="add-accreditation__body w-100 flex-column">
            <!-- <AccreditationForm
              @on-update-value="onUpdateValue"
              @on-taggle-form="onToggleForm"
              :value="userCertificates"/>
            <Button buttonText="confirm" :isActive="isFormReady" class="w-100"/> -->
            <CertificationForm
              style="width: 100% !important;"
              :userCertificates="userCertificates"
              :submitting="submitting"
              @add-to-certificate-list="addToCerticationList"
              :hideCancelCta="true"/>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

// import AccreditationForm from '@/core/components/common/forms/accreditation/AccreditationForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
// import Button from '@/core/components/ui/Button.vue';
import CertificationForm from '@/modules/account-setup/forms/CertificationForm.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    // AccreditationForm,
    // Button,
    CertificationForm
  },

  emits: ['on-cancel', 'push-to-address-list', 'update-address-list'],

  props: ['show', 'userCertificates'],

  data() {
    return {
      submitting: false,

      isFormReady: false,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  watch: {
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'addNewCertificate',
      'getUserCertificates',
      'updateUserCertificate',
      'removeUserCertificates',
      'getAccountStatuses'
    ]),

    initialize() {
    },

    addToCerticationList(certificate) {
      const certificateAlreadyExist = this.userCertificates.find((userCert) => userCert.certificate.id === certificate.type);

      if (certificateAlreadyExist) {
        this.$notify.error({
          message: 'Certificate already exist.'
        });
        return;
      }

      const userCertificate = {
        userId: this.user.id,
        certificateId: certificate.type,
        metadata: {
          registrationNumber: certificate.membershipNumber,
          url: certificate.url
        },
        attachments: certificate.attachments
      };

      this.submitting = true;

      this.addNewCertificate(userCertificate)
        .then(async () => {
          this.showForm = false;
          this.$notify.success({
            message: 'New certificate successfully saved.'
          });
          this.$emit('on-cancel', true);
        })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error submitting certificate details.'
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    cancel() {
      this.$emit('on-cancel');
    },
  },
});
</script>
<style lang="scss" scoped>
 .add-accreditation {
    min-width: 350px;
    position: relative;
    max-height: 500px;
    height: 100%;

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }
 }
</style>
